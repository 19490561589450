import styled from "styled-components";

import { theme } from "@/theme";

const Loader = styled.div`
  border: 5px solid ${theme.colors.gray[400]};
  border-top: 5px ${theme.colors.gray[500]} solid;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  animation: spin 0.7s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export default function Spinner() {
  return (
    <div>
      <Loader />
    </div>
  );
}
