import ExportedImage from "next-image-export-optimizer";
import styled from "styled-components";

import Box from "@/design-system/Box";
import Container from "@/design-system/Container";
import GridContainer from "@/design-system/GridContainer";
import SectionHeading from "@/design-system/SectionHeading";
import LeftBarBullet from "@/design-system/SectionHeading/LeftBarBullet";

import Testimonials from "@/components/CoursePage/BIM/CustomerReviews/Testimonials/testimonials";
import VideoTestimonial from "@/components/CoursePage/BIM/CustomerReviews/VideoTestimonials/VideoTestimonials";

import { theme } from "@/theme";

const Alumni = ({ BIMData }: { BIMData: any }) => {
  const data = BIMData;

  return (
    <Box>
      <Box
        backgroundColor={theme.colors.gray[100]}
        padding={{ _: "24px 0px", md: "80px 0px 200px 0px" }}
      >
        {" "}
        <Container>
          <GridContainer>
            <Box gridColumn="span 12">
              <Box
                style={{
                  height: "100%",
                  position: "absolute",
                  marginTop: "-98px",
                }}
              >
                <LeftBarBullet alignStart={"146px"} />
              </Box>
              <SectionHeading
                streak
                streakTitle={"Alumni Voices"}
                heading={
                  <span>
                    Hear our
                    <span style={{ color: theme.colors.purple[700] }}> community </span>
                    talk about their
                    <Box display={{ _: "none", md: "unset" }}>
                      <br />
                    </Box>
                    <span style={{ color: theme.colors.purple[700] }}> upskilling journeys</span>
                  </span>
                }
              />
            </Box>
          </GridContainer>

          <Box borderColor={theme.shadows.primary.xl} mt={{ _: "56px" }} mb={"56px"}>
            <VideoTestimonial data={data.videoTestimonials} isHome={true} />
          </Box>
          <Box mb={{ _: "8px", md: "0" }}>
            <Testimonials data={testimonialsData} />
          </Box>
        </Container>
      </Box>
      <UnionTopStyle>
        <ExportedImage
          src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/union5.svg`}
          fill
          alt=""
        />
      </UnionTopStyle>
      <UnionBottomStyle>
        <ExportedImage
          src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/union5.svg`}
          fill
          alt=""
        />
      </UnionBottomStyle>
    </Box>
  );
};

export default Alumni;

const UnionTopStyle = styled(Box)`
  position: absolute;
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
    width: 490px;
    height: 490px;
    right: -330px;
    top: -178px;
    z-index: 2;
  }
`;

const UnionBottomStyle = styled(Box)`
  position: absolute;
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
    width: 490px;
    height: 490px;
    left: -348px;
    bottom: -84px;
    z-index: 1;
  }
`;
const testimonialsData = [
  {
    name: "Cherine Husseini",
    userImage: {
      alt: "Cherine Husseini",
      src: "/images/mcd/cherine_husseini.png",
    },
    // badgeText: "141% Salary Hike",
    designation: "Cherine Husseini",
    // badgeIcon: "trend-up-01",
    // badgeColor: "primary",
    description:
      "I recommend this class to anyone in the industry who would look to learn the basics of rhino&GH. The contents are very explicit and clear to understand. There are exercises and weekly sessions to catch up with the involvement.",
    // cohort: "BIM Course, Sept Cohort’21",
  },
  {
    name: "Jerry Oola",
    userImage: {
      alt: "Jerry Oola",
      src: "/images/mcd/jerry_ola.png",
    },
    // badgeText: "160% Salary Hike",
    designation: "Freelance Designer",
    // badgeIcon: "trend-up-01",
    // badgeColor: "primary",
    description:
      "I loved the whole experience, it really was eye opening and it taught me to be brave with any software or anything in life I choose to do. Solong as you get time and put your mind to it.",
    // cohort: "BIM Course, Sept Cohort’21",
  },
  {
    name: "Prashanth .Hebbar",
    userImage: {
      alt: "Diya",
      src: "/images/mcd/prashanth_hebbar.png",
    },
    // badgeText: "First Job",
    designation: "Architect | Industrial Designer",
    // badgeIcon: "rocket-02",
    // badgeColor: "success",
    description:
      "I  completed my BIM Architecture course with Novatr, and it was a fantastic learning opportunity where all the concepts were clearly explained and applied to a real project. In the end, it was a valuable experience.",
    // cohort: "BIM Course, Sept Cohort’21",
  },
];
