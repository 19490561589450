import styled from "styled-components";
import { background, BackgroundProps, color, ColorProps } from "styled-system";

export interface StreakProps extends BackgroundProps, ColorProps {}
export const Streak = styled.span<StreakProps>`
  display: inline-block;
  height: 1px;
  width: 100%;
  max-width: 240px;
  margin-left: 16px;
  margin-top: auto;
  margin-bottom: auto;
  ${color}
  ${background}
`;
