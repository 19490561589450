import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import Box from "@/design-system/Box";
import UnstyledButton from "@/design-system/Button/UnstyledButton";
import FlexContainer from "@/design-system/FlexContainer";
import Icon from "@/design-system/Icon";
import Typography from "@/design-system/Typography";

import { theme } from "@/theme";

interface ContainerProps {
  customBorderRadius?: string;
  hideOverlay?: boolean;
}

const VideoContainer = styled(Box)<ContainerProps>`
  border-radius: ${({ customBorderRadius }) => customBorderRadius};
  video[poster] {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  ${({ hideOverlay }) =>
    !hideOverlay &&
    css`
      &::before {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.18) 53.37%, rgba(0, 0, 0, 0.6) 80.16%);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      }
    `}
`;

const OuterContainer = styled(Box)`
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

interface StylePropsTypes {
  isVisible: boolean;
}

const VideoPlayButtonContainer = styled(UnstyledButton)<StylePropsTypes>`
  position: absolute;
  top: 40%;
  left: calc(50% - 30px);
  z-index: 1;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  .button-container {
    width: 64px;
    height: 64px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
      background 0.3s ease,
      opacity 0.3s ease;

    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }

    &:active {
      background: rgba(0, 0, 0, 0.6);
    }

    .play-pause-icon {
      color: ${theme.colors.base.white};
      font-size: 32px;
    }
  }
`;

const FullScreenButton = styled(Icon)<StylePropsTypes>`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2;
  color: ${theme.colors.base.white};
  font-size: 24px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

const VideoPlayButton = ({
  buttonClick,
  elementKey,
  playStates,
  isHovered,
}: {
  buttonClick: (val: number) => void;
  elementKey: number;
  playStates: boolean[];
  isHovered: boolean;
}) => {
  const playState = playStates[elementKey];
  const isVisible = Boolean(!playState || isHovered);

  return (
    <VideoPlayButtonContainer
      id={`playButton${elementKey}`}
      onClick={() => {
        buttonClick(elementKey);
      }}
      isVisible={isVisible}
    >
      <div className="button-container">
        {playState ? (
          <Icon
            id={`playButtonIcon${elementKey}`}
            type="pause-circle"
            className="play-pause-icon"
            color={theme.colors.base.white}
          />
        ) : (
          <Icon
            id={`playButtonIcon${elementKey}`}
            type="play"
            className="play-pause-icon"
            varient={"solid"}
            color={theme.colors.base.white}
          />
        )}
      </div>
    </VideoPlayButtonContainer>
  );
};

interface VideoDataPropTypes {
  badgeColor: string;
  badgeIcon: string;
  designation?: string;
  imgSrc: string;
  name?: string;
  videoSrc: string;
}
interface VideoTestimonialPropTypes {
  data: VideoDataPropTypes[];
  isHome?: boolean;
  bottomBorder?: boolean;
  customHeight?: string;
  customBorderRadius?: string;
  hideOverlay?: boolean;
  controls?: boolean;
}

const VideoTestimonial = ({
  data,
  bottomBorder = false,
  hideOverlay,
  controls,
  customHeight = "420px",
  customBorderRadius = "8px",
  isHome = false,
}: VideoTestimonialPropTypes) => {
  const videoElement = useRef<any[]>([]);
  const videoContainer = useRef<null | HTMLDivElement>(null);
  const [hoveredElement, setHoveredElement] = useState<number | null>(null);

  const scrollFunction = () => {
    if (!isInViewport(videoElement.current[0])) {
      for (let i = 0; i < videoElement.current.length; i++) {
        if (!videoElement?.current[i]?.paused) videoElement?.current[i]?.pause();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollFunction);
    return () => {
      window.removeEventListener("scroll", scrollFunction);
    };
  }, []);

  const isInViewport = (element: any) => {
    const rect = element?.getBoundingClientRect();
    return rect?.y >= -Math.abs(rect?.height) && rect?.top <= window.innerHeight;
  };

  const [playStates, setPlayStates] = useState<boolean[]>(data.map(() => false)); // Initialize playStates

  function handleButtonClick(key: number) {
    const newPlayStates = data.map(() => false);
    if (videoElement?.current[key]?.paused) {
      newPlayStates[key] = true;
      videoElement?.current[key]
        ?.play()
        .then()
        .catch(() => {
          console.log("Play state error");
        });
    } else {
      newPlayStates[key] = false;
      videoElement?.current[key]?.pause();
    }
    videoElement?.current.map((element, index) => {
      if (index !== key) {
        element.pause();
      }
    });
    setPlayStates(newPlayStates);
  }

  return (
    <OuterContainer
      className="outercontainer"
      borderRadius={bottomBorder ? theme.radius(6) : theme.radius(0)}
    >
      <FlexContainer className="flexcontainer" width={"100%"} overflowX={"scroll"} gap={"24px"}>
        {data.map((videoTestimonial: VideoDataPropTypes, key: number) => {
          return (
            <Box
              height={customHeight}
              width={"100%"}
              key={key}
              zIndex={1}
              onMouseEnter={() => setHoveredElement(key)}
              onMouseLeave={() => setHoveredElement(null)}
            >
              <Box
                position={"absolute"}
                zIndex={2}
                left={"0"}
                px={theme.spacing(5)}
                bottom={"10px"}
                width={"100%"}
                pb={theme.spacing(2)}
              >
                {!playStates[key] ? (
                  <Box>
                    <Typography
                      color={theme.colors.base.white}
                      varient="heading5"
                      weightVarient="regular"
                      mb={"8px"}
                    >
                      {videoTestimonial.name}
                    </Typography>
                    <Typography
                      color={theme.colors.base.white}
                      varient="heading5"
                      weightVarient="regular"
                      mb={"8px"}
                    >
                      {videoTestimonial.designation}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
              <VideoContainer
                overflow={"hidden"}
                hideOverlay={hideOverlay}
                height={customHeight}
                customBorderRadius={customBorderRadius}
                zIndex={1}
                width={{ _: isHome ? "334px" : "100%", md: "100%" }}
              >
                <FullScreenButton
                  isVisible={hoveredElement === key && playStates[key]}
                  type="expand-01"
                  onClick={() => {
                    const video = videoElement.current[key];
                    if (video && video.requestFullscreen) {
                      video.requestFullscreen();
                    }
                  }}
                />
                <VideoPlayButton
                  buttonClick={handleButtonClick}
                  elementKey={key}
                  playStates={playStates}
                  isHovered={hoveredElement === key}
                />
                <video
                  id={`video${key}`}
                  ref={(el) => (videoElement.current[key] = el)}
                  poster={videoTestimonial?.imgSrc}
                  controls={controls}
                >
                  <source src={videoTestimonial?.videoSrc} />
                </video>
              </VideoContainer>
            </Box>
          );
        })}
      </FlexContainer>
    </OuterContainer>
  );
};

export default VideoTestimonial;
