import styled from "styled-components";

import { theme } from "@/theme";

export const StyledPracticalTrainingCard = styled.div`
  background-color: white;
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    box-shadow: ${theme.shadows.primary.md};
    min-height: 908px;
  }
`;
