import ExportedImage from "next-image-export-optimizer";
import styled from "styled-components";

import Box from "@/design-system/Box";
import Container from "@/design-system/Container";
import GridContainer from "@/design-system/GridContainer";
import SectionHeading from "@/design-system/SectionHeading";
import LeftBarBullet from "@/design-system/SectionHeading/LeftBarBullet";

import Testimonials from "@/components/CoursePage/BIM/CustomerReviews/Testimonials/testimonials";
import VideoTestimonial from "@/components/CoursePage/BIM/CustomerReviews/VideoTestimonials/VideoTestimonials";

import { theme } from "@/theme";

const UnionTopStyle = styled(Box)`
  position: absolute;
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
    width: 490px;
    height: 490px;
    right: -330px;
    top: -178px;
    z-index: 2;
  }
`;

const UnionBottomStyle = styled(Box)`
  position: absolute;
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
    width: 490px;
    height: 490px;
    left: -348px;
    bottom: -84px;
    z-index: 1;
  }
`;
const testimonialsData = [
  {
    name: "Aditya Gupta",
    userImage: {
      alt: "Aditya",
      src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/testimonials/aditya.png`,
    },
    badgeIcon: "trend-up-01",
    badgeColor: "primary",
    badgeText: "26% Salary Hike",
    designation: "Architect, DAR ",
    description:
      "This course has been an eye opener in terms of how the AEC industry has changed and how we need to be a part of this change. The enthusiasm of Oneistox team to make a difference for their students is heartwarming.",
    cohort: "Review of BIM Professional Course by Oneistox, (now Novatr)",
  },
  {
    name: "Aditi Shukla",
    userImage: {
      alt: "Aditi",
      src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/testimonials/aditi.png`,
    },
    badgeText: "First Job",
    badgeIcon: "rocket-02",
    badgeColor: "success",
    designation: "Jr BIM Architect, Techture",
    description:
      "The capstone project was my favourite part. It was a glimpse of how projects are handled in the real world. The gamification of the project and our progress made it even more interesting.",
    cohort: "Review of BIM Professional Course by Oneistox, (now Novatr)",
  },
  {
    name: "Ankita Maurya",
    userImage: {
      alt: "Ankita",
      src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/testimonials/ankita.png`,
    },
    badgeText: "25% Salary Hike",
    badgeIcon: "trend-up-01",
    badgeColor: "primary",
    designation: "BIM Architect, BIM Engineers",
    description:
      "BIM course truly designed as per the need of AEC industry. The whole team of Oneistox supported throughout the course and answered each query without any delay. Perfect course for professionals to upskill.",
    cohort: "Review of BIM Professional Course by Oneistox, (now Novatr)",
  },
];

const Alumni = ({ BIMData }: { BIMData: any }) => {
  const data = BIMData;

  return (
    <Box>
      <Box
        backgroundColor={theme.colors.gray[100]}
        padding={{ _: "24px 0px", md: "80px 0px 200px 0px" }}
      >
        {" "}
        <Container>
          <GridContainer>
            <Box gridColumn="span 12">
              <Box
                style={{
                  height: "100%",
                  position: "absolute",
                  marginTop: "-98px",
                }}
              >
                <LeftBarBullet alignStart={"146px"} />
              </Box>
              <SectionHeading
                streak
                streakTitle={"Alumni Voices"}
                heading={
                  <span>
                    Hear our
                    <span style={{ color: theme.colors.purple[700] }}> community </span>
                    talk about their
                    <Box display={{ _: "none", md: "unset" }}>
                      <br />
                    </Box>
                    <span style={{ color: theme.colors.purple[700] }}> upskilling journeys</span>
                  </span>
                }
              />
            </Box>
          </GridContainer>

          <Box borderColor={theme.shadows.primary.xl} mt={{ _: "56px" }} mb={"56px"}>
            <VideoTestimonial data={data.videoTestimonials} isHome={true} />
          </Box>
          <Box mb={{ _: "8px", md: "0" }}>
            <Testimonials data={testimonialsData} />
          </Box>
        </Container>
      </Box>
      <UnionTopStyle>
        <ExportedImage
          src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/union5.svg`}
          fill
          alt=""
        />
      </UnionTopStyle>
      <UnionBottomStyle>
        <ExportedImage
          src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/union5.svg`}
          fill
          alt=""
        />
      </UnionBottomStyle>
    </Box>
  );
};

export default Alumni;
