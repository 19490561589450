import styled from "styled-components";

import { theme } from "@/theme";

export const BecomeCardStyle = styled.div`
  border-radius: 8px;
  background: white;
  display: flex;
  height: 100%;
  box-shadow: ${theme.shadows.primary.md};
  z-index: 4;
`;
