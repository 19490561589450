import styled from "styled-components";
import { space } from "styled-system";

import { theme } from "@/theme";

import { CommonHeroSectionProps } from "./types";

export const StyledHeroSectionOuter = styled.div`
  padding: ${theme.spacing(0)} ${theme.spacing(4)} 0 ${theme.spacing(4)};

  .deadline {
    margin: auto;
    width: fit-content;
    background: rgba(46, 136, 146, 0.5);
    border-radius: 9999px;
    padding: 12px 20px;
    margin-bottom: 48px;

    .text {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
    .date {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
    }
  }

  /* Mobile-styles */
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-column: span 12;

    .deadline {
      background: #1f7073;

      .text {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        white-space: nowrap;
      }
      .date {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        white-space: nowrap;
      }
    }
  }
`;

export const StyledHeroSection = styled.div<CommonHeroSectionProps>`
  padding-top: ${({ pt }) => pt || "30px"};
  //padding-bottom: 57px;
  padding-bottom: 32px;
  height: ${({ height }) => height};
  overflow: ${({ overflow }) => overflow || "hidden"};
  position: relative;
  background-image: ${({ bg }) => bg || theme.gradients.primaryGradients45deg[7]};
  color: ${theme.colors.base.white};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: ${({ pt }) => pt || "64px"};
    border-radius: ${theme.spacing(6)};
    padding-bottom: 64px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-top: ${({ pt }) => pt || "120px"};
    padding-bottom: 120px;
  }
  ${space};
`;
