import { useKeenSlider } from "keen-slider/react";
import ExportedImage from "next-image-export-optimizer";
import { useState } from "react";
import styled from "styled-components";

import Box from "@/design-system/Box";
import FlexContainer from "@/design-system/FlexContainer";

import { theme } from "@/theme";

const ImageContainer = styled(Box)`
  width: 100%;
  /* height: 335px; */
  aspect-ratio: 577 / 439;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    /* height: 440px; */
  }
`;

const Dot = styled.div<{ active: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${theme.colors.primary[700]};
  opacity: ${(props) => (props.active ? 1 : 0.2)};
  margin-top: 24px;
  margin-bottom: 40px;
  cursor: pointer;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    background-color: white;
    margin-bottom: 33px;
  }
`;

const InnovativeSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [ref, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    // loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  return (
    <>
      <div ref={ref} className="keen-slider" style={{ width: "100%" }}>
        <ImageContainer className="keen-slider__slide">
          <ExportedImage
            fill
            alt="Interactive Live Sessions"
            src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/learning_1.png`}
            style={{ objectFit: "cover" }}
          />
        </ImageContainer>
        <ImageContainer className="keen-slider__slide">
          <ExportedImage
            fill
            alt="Skill Mapping"
            src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/learning_2.png`}
            style={{ objectFit: "cover" }}
          />
        </ImageContainer>
      </div>

      {loaded && instanceRef?.current && (
        <FlexContainer
          style={{ width: "100%", gap: "4px" }}
          flexDirection="row"
          justifyContent={"center"}
        >
          {[...(Array(instanceRef.current.track.details?.slides.length).keys() as any)].map(
            (idx) => {
              return (
                <Dot
                  active={currentSlide === idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx);
                  }}
                  key={idx}
                />
              );
            }
          )}
        </FlexContainer>
      )}
    </>
  );
};

export default InnovativeSlider;
