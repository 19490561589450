import ExportedImage from "next-image-export-optimizer";
import styled from "styled-components";

import Badge from "@/design-system/Badge/badge";
import Box from "@/design-system/Box";
import FlexContainer from "@/design-system/FlexContainer";
import PlacementCard from "@/design-system/PlacementCard";
import Typography from "@/design-system/Typography";

import { theme } from "@/theme";

const BadgeBoxStyle = styled(Box)`
  margin-top: -50px;
  margin-bottom: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: -60px;
    margin-left: -24px;
  }
`;

const PlacementCardComponent = ({ type, name, designation, description, badgeText, img }: any) => {
  let BadgeSelected;

  switch (type) {
  case "firstJob":
    BadgeSelected = (
      <Badge
        text={badgeText}
        icon="lightning-01"
        leadingIcon={true}
        size={"xl"}
        badgeStyle={"dark"}
        colorVarient={"success"}
      />
    );
    break;
  case "salaryHike":
    BadgeSelected = (
      <Badge
        text={badgeText}
        icon="lightning-01"
        leadingIcon={true}
        size={"xl"}
        badgeStyle={"dark"}
        colorVarient={"warning"}
      />
    );
    break;
  }

  return (
    <PlacementCard type={type}>
      <FlexContainer
        padding={{ md: "40px", _: "32px 16px" }}
        style={{
          backgroundColor: theme.colors.grayBlue["50"],
          minHeight: "244px",
          borderRadius: "12px",
        }}
        flexDirection={"column"}
      >
        <BadgeBoxStyle>{BadgeSelected}</BadgeBoxStyle>
        <FlexContainer alignItems={"center"}>
          <Box
            style={{
              width: "56px",
              height: "56px",
              borderRadius: "46px",
              backgroundColor: "pink",
              overflow: "hidden",
            }}
          >
            <ExportedImage fill alt="Aditya Shukla" src={img} style={{ objectFit: "cover" }} />
          </Box>
          <Box ml={{ _: "8px", md: "16px" }}>
            <Typography varient="heading6" weightVarient="semibold">
              {name}
            </Typography>
            <Typography varient="captionl" weightVarient="regular" color={theme.colors.gray[600]}>
              {designation}
            </Typography>
          </Box>
        </FlexContainer>
        <Typography varient="bodys" weightVarient="regular" mt={{ _: "16px", md: "24px" }}>
          {description}
        </Typography>
      </FlexContainer>
    </PlacementCard>
  );
};

export default PlacementCardComponent;
