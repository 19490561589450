import htmlParse from "html-react-parser";
import { ReactElement } from "react";

import Box from "@/design-system/Box";
import Divider from "@/design-system/Divider";
import FlexContainer from "@/design-system/FlexContainer";
import Typography from "@/design-system/Typography";

import { theme } from "@/theme";

interface HighlightProps {
  icon: ReactElement;
  title: string;
  details: string;
}

const Highlight = ({ icon, title, details }: HighlightProps) => {
  return (
    <FlexContainer
      flexDirection={"column"}
      padding={{ _: "16px", md: "0px" }}
      style={{ gap: "16px", width: "276px" }}
    >
      <Box>{icon}</Box>
      <Typography varient="heading5" weightVarient="semibold">
        {htmlParse(title)}
      </Typography>
      <Divider style={{ borderColor: theme.colors.gray[300] }} />
      <Typography varient="bodys" weightVarient="regular">
        {details}
      </Typography>
    </FlexContainer>
  );
};

export default Highlight;
