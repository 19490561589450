import styled from "styled-components";

import { useGetNextCohortDateQuery } from "@/client/lmsApiClient";

import Box from "@/design-system/Box";
import Container from "@/design-system/Container";
import GridContainer from "@/design-system/GridContainer";
import Typography from "@/design-system/Typography";

import { useAppSelector } from "@/hooks/hooks";

import { formatNextCohortDate } from "@/utils/utils";

import CoursesDynamicData from "@/content/courses/dynamic-data";

import { theme } from "@/theme";

import CourseCardComponent from "./CourseCardComponent";

const SectionHeadingStyle = styled(Box)`
  display: flex;
  align-items: center;
  grid-column: span 12;
  gap: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    grid-column: span 12;
  }
`;

const TitleStyle = styled(Box)`
  display: flex;
  align-items: center;
  grid-column: 1 / span 12;
  margin-top: 8px;
  margin-bottom: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    grid-column: span 12;
    margin-top: 16px;
    margin-bottom: unset;
  }
`;

const SubTitleStyle = styled(Box)`
  display: none;
  text-align: start;
  justify-content: flex-start;
  grid-column: span 12;
  margin-top: 8px;
  margin-bottom: 32px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 56px;
  }
`;

const BoxStyle = styled(Box)`
  background-color: ${theme.colors.primary["25"]};
  padding-top: 48px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: 40px;
    padding-bottom: 96px;
  }
`;

const OurCourses = ({ ctaExplore }: { ctaExplore: any }) => {
  const dynamicData = CoursesDynamicData;
  const { data: nextCohortDate } = useGetNextCohortDateQuery();
  const { BIM_A_US, BIM_A_ME, BIM_C_US, BIM_C_ME, MCD_US, MCD_ME } = nextCohortDate || {};
  const loginDetails = useAppSelector((state) => state?.rootReducer?.auth);
  const isMena = Boolean(loginDetails?.userLocation === "MENA");

  return (
    <BoxStyle id="courses" style={{ marginTop: ctaExplore ? "20px" : "0px" }}>
      <Container>
        <GridContainer padding={{ _: "16px 8px", md: "16px 64px" }}>
          <SectionHeadingStyle>
            <Box
              style={{ backgroundColor: theme.colors.primary[50] }}
              display={"flex"}
              padding={"8px 12px"}
              borderRadius={"20px"}
            >
              <Typography
                varient="bodyl"
                weightVarient="regular"
                mdVarient="bodys"
                mdWeightVarient="medium"
                color={theme.colors.primary[700]}
              >
                Our Programs
              </Typography>
            </Box>
          </SectionHeadingStyle>
          <TitleStyle>
            <Typography
              varient="heading1"
              weightVarient="semibold"
              color={theme.colors.primary[800]}
              as={"h2"}
            >
              Your Future Starts Here
            </Typography>
          </TitleStyle>
          <SubTitleStyle>
            <Typography varient="bodyl" weightVarient="regular" color={theme.colors.gray[600]}>
              Get closer to your professional goal with Online certificate programs in architecture,{" "}
              <br />
              engineering, and design.
            </Typography>
          </SubTitleStyle>
        </GridContainer>
        <GridContainer paddingBottom={{ _: "48px", md: "80px 0px 80px 0px" }}>
          <Box gridColumn={{ _: "1 / span 12", md: "span 4" }}>
            <CourseCardComponent
              imgSrc={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/bim.png`}
              imgAlt="BIM Professional Course for Architects"
              courseType="BIM"
              courseSubText="Enter the AEC industry's most in-demand specialisation."
              adText="Enter the AEC industry's most in-demand specialisation."
              programDuration={
                dynamicData.find((c) => c.courseId === "BIM_US")?.upcomingCohortDuration
              }
              nextCohort={formatNextCohortDate(isMena ? BIM_A_ME?.date : BIM_A_US?.date)}
              idx={0}
            />
          </Box>
          <Box gridColumn={{ _: "1 / span 12", md: "span 4" }} mt={{ _: "32px", md: "0px" }}>
            <CourseCardComponent
              imgSrc={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/mcd.png`}
              imgAlt="Master Computational Design Course"
              courseType="MCD"
              courseSubText="Join the league of the top 1% architects and engineers."
              adText="Join the league of the top 1% architects and engineers."
              programDuration={
                dynamicData.find((c) => c.courseId === "acm_us")?.upcomingCohortDuration
              }
              nextCohort={formatNextCohortDate(isMena ? MCD_ME?.date : MCD_US?.date)}
              idx={1}
            />
          </Box>
          <Box gridColumn={{ _: "1 / span 12", md: "span 4" }} mt={{ _: "32px", md: "0px" }}>
            <CourseCardComponent
              imgSrc={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/civil.jpg`}
              imgAlt="BIM Professional Course for Civil Engineers"
              courseType="BIM_C"
              courseSubText="The future is bright for those who are a part of the digital transformation."
              adText="The future is bright for those who are a part of the digital transformation."
              programDuration={
                dynamicData.find((c) => c.courseId === "BIM_C_US")?.upcomingCohortDuration
              }
              nextCohort={formatNextCohortDate(isMena ? BIM_C_ME?.date : BIM_C_US?.date)}
              idx={1}
            />
          </Box>
        </GridContainer>
      </Container>
    </BoxStyle>
  );
};
export default OurCourses;
