import Link from "next/link";

import Box from "@/design-system/Box";
import GrayButton from "@/design-system/Button/GrayButton";
import Container from "@/design-system/Container";
import Typography from "@/design-system/Typography";

import { theme } from "@/theme";

const CTABanner = ({
  setIsOpen,
  bannerHeading,
  bannerText,
  bannerSubText,
  ctaText,
  ctaLink,
  setCtaExplore,
  className,
  disableLink,
}: {
  setIsOpen?: any;
  bannerHeading?: string;
  bannerText?: string;
  bannerSubText?: string;
  ctaText?: string;
  ctaLink?: string;
  shouldRedirect?: boolean;
  setCtaExplore?: any;
  className?: string;
  disableLink?: boolean;
}) => {
  return (
    <Box background={theme.colors.gray[800]}>
      <Container>
        <Box
          p={{ _: "40px 16px", md: "80px 32px" }}
          display={"grid"}
          gridTemplateColumns={{ _: "1fr", md: "1fr auto" }}
          gridGap={"35px"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Box>
            <Typography
              varient="bodyl"
              weightVarient="regular"
              color={theme.colors.primary[200]}
              mb={{ _: theme.spacing(2), md: theme.spacing(4) }}
            >
              {bannerHeading || "Your next chapter in AEC begins with Novatr!"}
            </Typography>
            <Box maxWidth={"788px"}>
              <Typography
                varient="displays"
                weightVarient="medium"
                color={theme.colors.base.white}
                mb={{ _: theme.spacing(2), md: theme.spacing(4) }}
                as={"h3"}
              >
                {bannerText || "Ready to skyrocket your career?"}
              </Typography>
            </Box>
            <Typography
              varient="bodyl"
              weightVarient="regular"
              color={theme.colors.primary[200]}
              mb={{ _: theme.spacing(10) }}
            >
              {bannerSubText ||
                "As you would have gathered, we are here to help you take the industry by storm with advanced, tech-first skills."}
            </Typography>
          </Box>
          <Box>
            {disableLink ? (
              <GrayButton
                label={ctaText}
                className={className}
                fullWidth
                onClick={() =>
                  setCtaExplore ? setCtaExplore(true) : setIsOpen ? setIsOpen(true) : null
                }
              />
            ) : (
              <Link href={ctaLink ? ctaLink : ""}>
                <GrayButton
                  label={ctaText}
                  className={className}
                  fullWidth
                  onClick={() =>
                    setCtaExplore ? setCtaExplore(true) : setIsOpen ? setIsOpen(true) : null
                  }
                />
              </Link>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CTABanner;
