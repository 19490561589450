import Lottie from "lottie-react";
import router from "next/router";
import ExportedImage from "next-image-export-optimizer";
import { useCallback, useContext, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

import { trackleadform } from "@/analytics/analytics";

import Box from "@/design-system/Box";
import Icon from "@/design-system/Icon";
import useMediaQuery from "@/design-system/useMediaQuery";

import FlexContainer from "@/design-system-v2/FlexContainer";

import { useScreenTime } from "@/hooks/useScreenTime";

import { PreviousRouteProvider } from "@/pages/_app";

import PrimaryButton from "@/design-system-supernova/Button/PrimaryButton";
import Typography from "@/design-system-supernova/Typography";

import { theme } from "@/theme";

import CircleAnimation from "../../../../../public/lottie/Circle.json";

const fadeInOut = keyframes`
  0% { opacity: 0; transform: translateY(50px); }
  20% { opacity: 1; transform: translateY(0); }
  80% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-50px); }
`;

// Styled component for animated text
const AnimatedText = styled.span`
  display: inline-block;
  animation: ${fadeInOut} 3s ease-in-out infinite;
  color: ${({ theme }) => theme.colors.cyan["300"]};
`;

type TextProps = string[];
// Text switcher component
const AnimatedTextSwitcher = () => {
  const texts: TextProps = [
    "Architectural Designer",
    "Landscape Designer",
    "Sustainable Architect",
    "Urban Designer",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000); // Changing text in every 3 seconds

    return () => clearInterval(interval);
  }, [texts.length]);

  return <AnimatedText key={texts[currentIndex]}>{texts[currentIndex]}</AnimatedText>;
};

const CareerQuizPopup = () => {
  const [trackObj, setTrackObj] = useState<any>(null);
  const time = useScreenTime();
  const { previousRoute } = useContext(PreviousRouteProvider);
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.md})`);
  useEffect(() => {
    setTrackObj({
      Page_path: window.location.pathname,
      Page_Referrer: previousRoute,
    });
  }, [router?.isReady]);

  const handleButtonClick = (e) => {
    const obj = { ...trackObj };
    obj["on_screen_duration"] = time.getFormattedScreenTime();
    obj["Button_text"] = e.currentTarget.innerText;

    // Button-click tracking
    trackleadform("Button_Click", obj);
  };

  //   Memoized function to render quiz descriptions

  const renderQuizDescriptions = useCallback(() => {
    return quizDescriptions.map((quizDescription) => (
      <FlexContainer
        gap={theme.spacing(1)}
        maxWidth={isMobile ? 296 : "auto"}
        key={quizDescription.id}
      >
        <Icon varient="solid" type={"stars-01"} size={24} color={theme.colors.base.white} />
        <Typography weightVarient="medium" varient="bodyl" color={theme.colors.base.white}>
          {quizDescription.text}
        </Typography>
      </FlexContainer>
    ));
  }, [isMobile]);

  return (
    <Box
      position={"relative"}
      p={theme.spacing(4)}
      height={{ _: "470px", md: "400px" }}
      marginLeft={"20px"}
      marginRight={"20px"}
      marginBottom={{ _: "5px", md: "-20px" }}
    >
      <ChildCard>
        {/* Left Lottie Animation */}
        <Box
          position={"absolute"}
          opacity={"0.5"}
          zIndex={1}
          width={"660px"}
          height={"660px"}
          left={{ _: "-300px", md: "-48px" }}
          top={{ _: "-454px", md: "-100px" }}
        >
          <Lottie animationData={CircleAnimation} />
        </Box>
        {/* Right Image */}
        <Box
          position={"absolute"}
          right={!isMobile && 0}
          top={!isMobile && 0}
          left={isMobile && 0}
          bottom={isMobile && 0}
          zIndex={1}
        >
          <ExportedImageWrapper
            src={"/images/home/bg-img.png"}
            width={isMobile ? 300 : 789}
            height={isMobile ? 200 : 432}
            alt="bg-img"
          />
        </Box>
        {/* Text and Button Overlay */}
        <ContentWrapper>
          <Box>
            <Typography weightVarient="semibold" varient="heading2">
              <SpanWrapperWhite>Discover your ideal &nbsp;</SpanWrapperWhite>{" "}
              <SpanWrapperCyan>Career Path&nbsp;</SpanWrapperCyan>
              <SpanWrapperWhite>as a &nbsp;</SpanWrapperWhite>
              <AnimatedTextSwitcher />
            </Typography>
            <FlexContainer
              py={theme.spacing(10)}
              gap={isMobile && "12px"}
              flexDirection={{ _: "column", md: "row" }}
            >
              {renderQuizDescriptions()}
            </FlexContainer>
            <PrimaryButtonWrapper
              shape="rounded"
              fullWidth={true}
              buttonType="primaryDark"
              label="Discover Your Career Path"
              size={isMobile ? "xs" : "lg"}
              suffixIcon={{
                variant: "line",
                type: "arrow-narrow-up-right",
                color: theme.colors.base.white,
              }}
              style={{ maxWidth: isMobile ? "225px" : "400px" }}
              onClick={(e) => {
                handleButtonClick(e); // Handling button click tracking
                router.push(
                  `/career-quiz/questions/personal-details?utm_source=hero_section_banner&utm_medium=discover_your_career_path`
                );
              }}
            />
          </Box>
          <ExportedImage
            src={"/images/home/see-quiz-button.svg"}
            width={isMobile ? 148 : 200}
            height={isMobile ? 142 : 248}
            alt="see-quiz-button"
            style={{
              top: isMobile ? "-86px" : "-134px",
              left: isMobile ? "204px" : "382px",
            }}
          />
        </ContentWrapper>
      </ChildCard>
    </Box>
  );
};

export default CareerQuizPopup;

const ChildCard = styled.div`
  width: 100%;
  height: 530px;
  border-radius: 16px;
  background: linear-gradient(to right, #2575fc 0%, #6a11cb 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 0.99;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  overflow: hidden; /* Ensure child elements don't overflow the card */
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 85%;
    height: 400px;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 32px;
  }
`;

const ContentWrapper = styled.div`
  position: absolute;
  width: 100%;
  padding: 16px;
  z-index: 2; /* content above both layers */

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 64px;
  }
`;

const PrimaryButtonWrapper = styled(PrimaryButton)`
  position: relative;
  overflow: hidden;
  background: linear-gradient(0deg, #fd371f 0%, #f49062 100%);
  color: ${theme.colors.base.white};
  border: none;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -72%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      /* Slant angle */ transparent 40%,
      /* Transparent start */ ${theme.colors.orangeDark["300"]} 42%,
      /* First bold line */ transparent 44%,
      /* Spacing between lines */ ${theme.colors.orangeDark["300"]} 46%,
      /* Second bold line */ transparent 48% /* Transparent end */
    );
    background-size: 200%;
    animation: shimmer 1.5s infinite linear;
  }

  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

const SpanWrapperWhite = styled.span`
  color: ${theme.colors.base.white};
`;

const SpanWrapperCyan = styled.span`
  color: ${theme.colors.cyan["300"]};
`;

const ExportedImageWrapper = styled(ExportedImage)`
  /* Mobile view */
  width: 100%;
  height: 100%;
  object-fit: contain;

  /* Desktop view */
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
    height: auto;
    max-height: 432px; /* Ensure the image height does not exceed the container's height */
    object-fit: cover;
  }
`;

interface QuizDescriptionsProps {
  id: string;
  text: string;
}
const quizDescriptions: QuizDescriptionsProps[] = [
  { id: "1", text: "Make an informed career choice with data driven salary insights" },
  { id: "2", text: "Gain a competitive edge with a personalised report" },
  { id: "3", text: "Discover the most sought-after jobs and skills in your industry" },
];
