import { useContext, useEffect, useState } from "react";

import { trackleadform } from "@/analytics/analytics";

import Seo from "@/components/Seo";

import Box from "@/design-system/Box";

import { useAppSelector } from "@/hooks/hooks";
import { useScreenTime } from "@/hooks/useScreenTime";

import { PreviousRouteProvider } from "@/pages/_app";

import { formatNextCohortDate, getInteger, getScrollPositionByPercentage } from "@/utils/utils";

import CTABanner from "@/components/CoursePage/BIM/Footer/ctaBanner";
import PRSection from "@/components/Impact/PRSection";
import BannerNew from "@/components/Layout/BannerNew";
import Layout from "@/components/Layout/Layout";
import WhatsappWidget from "@/components/Layout/WhatsappWidget";
import WhatsappWidgetMobile from "@/components/Layout/WhatsappWidgetMobile";
import BIMPageData from "@/content/courses/bim";

import Alumni from "./Alumni";
import Become from "./Become";
import ExpertMentors from "./ExpertMentors";
import HeroSection from "./HeroSection";
import InnovativeLearning from "./InnovativeLearning";
import OurCourses from "./OurCourses";
import OurImpact from "./OurImpact";
import PlacementAssistance from "./PlacementAssistance";
import PracticalTraining from "./PraticalTraining";
import Resources from "./Resources";

const India = ({ data, blogsList }) => {
  const [ctaExplore, setCtaExplore] = useState(false);
  const [trackObj, setTrackObj] = useState(null);
  const time = useScreenTime();
  const { previousRoute } = useContext(PreviousRouteProvider);
  const loginDetails = useAppSelector((state) => state?.rootReducer?.auth);
  useEffect(() => {
    setTrackObj({
      Page_path: window?.location?.pathname,
      Page_Referrer: previousRoute,
      isLoggedIn: loginDetails?.isAuthenticated,
    });
  }, [loginDetails]);

  useEffect(() => {
    if (trackObj?.Page_Referrer) {
      // Page-view event tracking
      setTimeout(() => {
        trackleadform("Page_view", trackObj);
      }, 750);
    }

    // Link/Button-click event tracking
    const observer = new MutationObserver(() => {
      addEventListeners();
    });
    const observerOptions = {
      childList: true,
      subtree: true,
    };
    observer.observe(document, observerOptions);

    return () => {
      removeEventListeners();
      observer.disconnect();
    };
  }, [trackObj]);

  const handleLinkClick = (e) => {
    const linkClickTrackObj = {
      ...trackObj,
      link_text: e.currentTarget.innerText,
    };
    linkClickTrackObj["on_screen_duration"] = time.getFormattedScreenTime();
    linkClickTrackObj["percent_scrolled"] = getScrollPositionByPercentage();
    trackleadform("Link_Click", linkClickTrackObj);
  };

  const handleButtonClick = (e) => {
    const obj = { ...trackObj };
    obj["on_screen_duration"] = time.getFormattedScreenTime();
    obj["percent_scrolled"] = getScrollPositionByPercentage();

    obj["Button_text"] = e.currentTarget.innerText;
    trackleadform("Button_Click", obj);
  };

  const addEventListeners = () => {
    const allButtonElements = document.getElementsByTagName("button");
    const allLinkElements = document.getElementsByTagName("a");
    Array.from(allButtonElements).forEach((element) => {
      element?.addEventListener("click", handleButtonClick);
    });
    Array.from(allLinkElements).forEach((element) => {
      element?.addEventListener("click", handleLinkClick);
    });
  };

  const removeEventListeners = () => {
    const allButtonElements = document.getElementsByTagName("button");
    const allLinkElements = document.getElementsByTagName("a");
    Array.from(allButtonElements).forEach((element) => {
      element?.removeEventListener("click", handleButtonClick);
    });
    Array.from(allLinkElements).forEach((element) => {
      element?.removeEventListener("click", handleLinkClick);
    });
  };

  return (
    <>
      {/* <BannerNew /> */}
      <Layout courseName="Home">
        <Seo
          data={data.home}
          title="Online Professional Courses for Designers, Architects & Engineers | Novatr"
        />
        <Box>
          <HeroSection />
        </Box>
        <OurImpact />
        <OurCourses ctaExplore={ctaExplore} />
        <ExpertMentors BIMData={BIMPageData} />
        <PracticalTraining />
        <PlacementAssistance />
        <InnovativeLearning />
        <Box overflow={"hidden"}>
          <Become />
          <Resources blogsList={blogsList} />
          <Alumni BIMData={BIMPageData.customerReviews} />
        </Box>
        <PRSection />
        <CTABanner
          bannerHeading="Ready to skyrocket your career?"
          bannerText="Your next chapter in AEC begins with Novatr!"
          bannerSubText="As you would have gathered, we are here to help you take the industry by storm with advanced, tech-first skills."
          ctaText="Explore Courses"
          className="button__explore_courses"
          ctaLink="#courses"
          setCtaExplore={setCtaExplore}
        />

        <WhatsappWidget />
        <WhatsappWidgetMobile />
      </Layout>
    </>
  );
};

export default India;
