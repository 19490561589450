import styled from "styled-components";

import Box from "@/design-system/Box";
import Icon from "@/design-system/Icon";
import { IconTypes } from "@/design-system/Icon/types";
import Typography from "@/design-system/Typography";

import { theme } from "@/theme";

const ContentWrapper = styled(Box)`
  width: 100%;
  padding: 0px 8px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 41%;
    margin-top: 80px;
    margin-bottom: 80px;
    min-height: 274px;
  }
`;

const SeparatorLine = styled.div`
  width: 100%;
  height: 2px;
  border-left: 1px solid ${theme.colors.primary[500]};
  background-color: ${theme.colors.primary[800]};
`;

const InnovativeCard = ({
  title,
  details,
  imgUrl,
}: {
  title: any;
  details: string;
  imgUrl: string;
}) => {
  return (
    <ContentWrapper>
      <Box
        style={{
          width: "48px",
          height: "48px",
        }}
        display={{ _: "none", md: "block" }}
      >
        <Icon type={imgUrl as IconTypes} varient="scaled" size={48} color="white" />
      </Box>
      <Box
        style={{
          width: "48px",
          height: "48px",
        }}
        display={{ _: "block", md: "none" }}
      >
        <Icon
          type={imgUrl as IconTypes}
          varient="scaled"
          size={48}
          color={theme.colors.primary[800]}
        />
      </Box>
      <Box style={{ padding: "0px 8px" }}>
        <Typography
          varient="heading5"
          weightVarient="medium"
          color={{ _: theme.colors.primary[800], md: "white" }}
          mt={"16px"}
          mb={"8px"}
        >
          {title}
        </Typography>
        <SeparatorLine />
        <Typography
          varient="bodys"
          weightVarient="regular"
          color={{ _: theme.colors.gray[700], md: theme.colors.primary[300] }}
          mt={"8px"}
        >
          {details}
        </Typography>
      </Box>
    </ContentWrapper>
  );
};

export default InnovativeCard;
