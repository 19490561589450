import styled from "styled-components";

import { theme } from "@/theme";

export const StyledImpactCard = styled.div`
  background-color: ${theme.colors.primary[25]};
  border: 1px solid ${theme.colors.primary[200]};
  display: grid;
  align-content: space-between;
  border-radius: 24px;
  padding: 24px;
  min-height: 228px;
  z-index: 4;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 40px;
    min-height: 340px;
  }
`;
