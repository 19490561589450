import BecomeCard from "@/components/BecomeCard";

import Box from "@/design-system/Box";
import Container from "@/design-system/Container";
import GridColumn from "@/design-system/GridColumn";
import GridContainer from "@/design-system/GridContainer";

import { theme } from "@/theme";

const Become = () => {
  return (
    <Box
      background={theme.colors.gray[100]}
      minHeight={"552px"}
      paddingTop={{ _: "40px", md: "80px" }}
      paddingBottom={{ _: "40px", md: "80px" }}
    >
      <Container>
        <GridContainer alignItems="center" style={{ minHeight: "392px" }}>
          <GridColumn xs="span 12" md="span 6" height={{ md: "100%" }}>
            <BecomeCard
              titleText="Become a Mentor"
              subText="Enable young professionals to make their greatest career leap."
              link="/apply-as-mentor"
              imgSrc={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/mentor.png`}
              imgAlt="Become a Mentor at Novatr"
            />
          </GridColumn>

          <GridColumn
            xs="span 12"
            md="span 6"
            height={{ md: "100%" }}
            marginTop={{ _: "32px", md: "0px" }}
          >
            <BecomeCard
              titleText="Become a Hiring Partner"
              subText="Hire highly skilled AEC professionals at zero cost."
              link="/hire-from-us"
              imgSrc={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/hiring_partner.png`}
              imgAlt="Become a Hiring Partner at Novatr"
            />
          </GridColumn>
        </GridContainer>
      </Container>
    </Box>
  );
};

export default Become;
