import ExportedImage from "next-image-export-optimizer";

import Box from "@/design-system/Box";
import Divider from "@/design-system/Divider";
import Typography from "@/design-system/Typography";

import { theme } from "@/theme";

import { StyledCardContent, StyledMentorCard } from "./mentorcard.styles";

export interface MentorCardDataObj {
  name: string;
  designation?: string;
  location: string;
  description?: string;
  introduction?: string;
  src: string;
  photo?: string;
  icon: string;
  iconWhite: string;
  iconSize?: {
    width: string | number;
    height: string | number;
  };
}

interface MentorCardProps {
  data: MentorCardDataObj;
}

const MentorCard = ({ data }: MentorCardProps) => {
  return (
    <StyledMentorCard>
      <Box
        borderRadius={"8px"}
        overflow="hidden"
        backgroundImage="linear-gradient(rgba(0, 0, 0, 0) 29.51%,rgba(0, 0, 0, 0.1) 100%)"
      >
        {data?.photo && (
          <ExportedImage
            style={{
              zIndex: 1,
              objectFit: "cover",
              height: "100%",
              maxHeight: "426px",
            }}
            height={426}
            width={280}
            src={data.photo}
            alt=""
          />
        )}
        {data?.src && (
          <ExportedImage
            style={{ zIndex: 1, objectFit: "cover", height: "100%" }}
            height={426}
            width={280}
            src={data.src}
            alt=""
          />
        )}
        <Box position={"absolute"} zIndex={1} bottom={10} width="100%" textAlign="center">
          <Typography varient="bodyl" weightVarient="semibold" color={"white"} as={"h5"}>
            {data?.name}
          </Typography>
        </Box>
      </Box>
      <StyledCardContent className="overlayName">
        <div>
          <Typography varient="heading5" weightVarient="semibold" mb={theme.spacing(2)}>
            {/* Brice <br />
            Pannetier */}
            {data?.name}
          </Typography>

          <Divider mb={2} />
          <Typography varient="bodyxs" mb={theme.spacing(2)}>
            {data?.designation}
          </Typography>
          {data?.location && (
            <Typography
              varient="bodyxs"
              weightVarient="semibold"
              color={"primary.600"}
              mb={theme.spacing(2)}
            >
              {data?.location}
            </Typography>
          )}
          {data?.description && (
            <Typography varient="bodyxs" weightVarient="medium" mb={theme.spacing(2)}>
              {data?.description}{" "}
            </Typography>
          )}
          {data?.introduction && (
            <Typography varient="bodyxs" weightVarient="medium" mb={theme.spacing(2)}>
              {data?.introduction}
            </Typography>
          )}
        </div>
        {/* <Box display={{ _: "none", md: "block" }}>
          <ExportedImage
            src={data.icon}
            width={data.iconSize?.width || 111}
            height={data.iconSize?.height || 25}
            objectFit="cover"
            alt=""
          />
        </Box> */}
        {/* {
          data?.iconWhite && data?.iconSize && <Box display={{ _: "block", md: "none" }}>
          <ExportedImage
            src={data?.iconWhite}
            width={(data?.iconSize?.width as number) || 111}
            height={(data?.iconSize?.height as number) || 25}
            alt=""
          />
        </Box>
        } */}
      </StyledCardContent>
    </StyledMentorCard>
  );
};

export default MentorCard;
