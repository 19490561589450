import ExportedImage from "next-image-export-optimizer";
import Marquee from "react-fast-marquee";
import styled from "styled-components";

import Box from "@/design-system/Box";
import MentorCard from "@/design-system/Card/MentorCard";
import Container from "@/design-system/Container";
import FlexContainer from "@/design-system/FlexContainer";
import GridContainer from "@/design-system/GridContainer";
import SectionHeading from "@/design-system/SectionHeading";

import { theme } from "@/theme";

const ImageStyleBox = styled(FlexContainer)`
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-right: 64px;
  }
`;

const ScrollbarDiv = styled(Box)`
  width: 100%;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ExpertMentors = ({ BIMData }: { BIMData: any }) => {
  const data = BIMData.courseSection.mentorPanel;
  return (
    <Box backgroundColor={theme.colors.gray[100]} padding={{ _: "32px 0px", md: "80px 0px" }}>
      <Container>
        <GridContainer>
          <Box gridColumn="span 12">
            <SectionHeading
              streak
              streakTitle={"Expert Mentors"}
              heading={
                <span>
                  Learn from the
                  <span style={{ color: theme.colors.purple[700] }}> Industry's Best</span>
                </span>
              }
              description={
                <span>
                  Our mentors are industry experts with experience at top-tier AEC firms and on
                  major projects worldwide.
                </span>
              }
            />
          </Box>
        </GridContainer>
      </Container>
      <Box margin={{ _: "64px 0px", md: "80px 0px" }}>
        <Marquee gradient={false} speed={80} direction="left">
          <ImageStyleBox>
            <ExportedImage
              alt={"AECOM"}
              height={40}
              width={171}
              src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/expertMentors/aecom.svg`}
            />
          </ImageStyleBox>
          <ImageStyleBox>
            <ExportedImage
              alt={"UNS"}
              height={40}
              width={100}
              src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/expertMentors/uns.svg`}
            />
          </ImageStyleBox>
          <ImageStyleBox>
            <ExportedImage
              alt={"RSP"}
              height={40}
              width={115}
              src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/expertMentors/rsp.svg`}
            />
          </ImageStyleBox>
          <ImageStyleBox>
            <ExportedImage
              alt={"Populous"}
              height={40}
              width={206}
              src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/expertMentors/populous.svg`}
            />
          </ImageStyleBox>
          <ImageStyleBox>
            <ExportedImage
              alt={"Henning Larsen"}
              height={40}
              width={126}
              src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/expertMentors/henning_larsen.svg`}
            />
          </ImageStyleBox>

          <ImageStyleBox>
            <ExportedImage
              alt={"Buro Happold"}
              height={40}
              width={255}
              src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/expertMentors/buro_haffold.svg`}
            />
          </ImageStyleBox>
          <ImageStyleBox>
            <ExportedImage
              alt={"External Reference"}
              height={40}
              width={136}
              src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/expertMentors/external_reference.svg`}
            />
          </ImageStyleBox>
          <ImageStyleBox>
            <ExportedImage
              alt={"Benoy"}
              height={40}
              width={145}
              src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/expertMentors/benoy.svg`}
            />
          </ImageStyleBox>
          <ImageStyleBox>
            <ExportedImage
              alt={"HH"}
              height={40}
              width={43}
              src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/expertMentors/hh.svg`}
            />
          </ImageStyleBox>
          <ImageStyleBox>
            <ExportedImage
              alt={"A+DA"}
              height={40}
              width={75}
              src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/expertMentors/ada.svg`}
            />
          </ImageStyleBox>
          <ImageStyleBox>
            <ExportedImage
              alt={"KPF"}
              height={40}
              width={108}
              src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/expertMentors/kpf.svg`}
            />
          </ImageStyleBox>
          <ImageStyleBox>
            <ExportedImage
              alt={"Cooper Carry"}
              height={40}
              width={128}
              src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/expertMentors/cooper.svg`}
            />
          </ImageStyleBox>
          <ImageStyleBox style={{ marginRight: "24px" }}>
            <ExportedImage
              alt={"PROTA Software"}
              height={40}
              width={306}
              src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/expertMentors/prota_software.svg`}
            />
          </ImageStyleBox>
        </Marquee>
      </Box>
      <ScrollbarDiv>
        <Box style={{ display: "flex" }}>
          {data.mentors.map((mentor: any, key: any) => {
            return <MentorCard key={key} data={mentor} />;
          })}
          {data.mentors.map((mentor: any, key: any) => {
            return <MentorCard key={key + 4} data={mentor} />;
          })}
        </Box>
      </ScrollbarDiv>
    </Box>
  );
};

export default ExpertMentors;
