import styled from "styled-components";

import Box from "@/design-system/Box";
import Container from "@/design-system/Container";
import GridColumn from "@/design-system/GridColumn";
import GridContainer from "@/design-system/GridContainer";
import Icon from "@/design-system/Icon";
import SectionHeading from "@/design-system/SectionHeading";
import LeftBarBullet from "@/design-system/SectionHeading/LeftBarBullet";

import SliderText from "@/components/CommonComps/SliderText";

import { theme } from "@/theme";

import ImpactCardComponent from "./ImpactCardComponent";

const Blob = styled(Box)`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: unset;
    position: absolute;
    border-radius: 50%;
    filter: blur(130px);
    opacity: 0.2;
    z-index: 2;
  }
`;

const OurImpact = () => {
  const data = ["Acceleration", "Expert Mentors", "Industry Knowledge", "Tech-first skills"];

  return (
    <Box padding={{ _: "32px 0px", md: "80px 0px" }} overflow={"hidden"}>
      <Container padding={{ _: "0px 0px", md: "40px 0px" }} mb={{ _: "48px", md: "80px" }}>
        <GridContainer>
          <GridColumn md={"span 6"}>
            <Box
              style={{
                height: "100%",
                position: "absolute",
                marginTop: "-150px",
              }}
            >
              <LeftBarBullet alignStart={"175px"} />
            </Box>

            <SectionHeading
              streak
              streakTitle={"Our Impact"}
              heading={
                <span>
                  Moving the Needle with <br />
                  <span style={{ color: theme.colors.purple[700] }}>Tech-First Education</span>
                </span>
              }
              description={
                <span>
                  A world of opportunities awaits architecture, engineering, and construction (AEC)
                  professionals. Novatr is your key to unlocking it.
                </span>
              }
            />
          </GridColumn>
        </GridContainer>

        <GridContainer mt={{ _: "32px", md: "40px" }}>
          <Box gridColumn={{ _: "span 6", md: "span 3" }}>
            <ImpactCardComponent
              Icon={
                <Icon
                  color={theme.colors.purple[700]}
                  type={"Scaled - N - Globe"}
                  varient={"scaled"}
                  size={48}
                />
              }
              stats="45+"
              title="Countries Represented"
            />
          </Box>
          <Box
            gridColumn={{ _: "span 6", md: "span 3" }}
            // mr={{ _: "16px", md: "0" }}
          >
            <ImpactCardComponent
              Icon={
                <Icon
                  color={theme.colors.purple[700]}
                  type={"Scaled - N - Graduation"}
                  varient={"scaled"}
                  size={48}
                />
              }
              stats="5000+"
              title="Successful Graduates"
            />
          </Box>

          <Box
            gridColumn={{ _: "span 6", md: "span 3" }}
            mt={{ _: "16px", md: "0" }}
            // mr={{ _: "16px", md: "0" }}
          >
            <ImpactCardComponent
              Icon={
                <Icon
                  color={theme.colors.purple[700]}
                  type={"Scaled - N - Briefcase"}
                  varient={"scaled"}
                  size={48}
                />
              }
              stats="93%"
              title="Placement Rate"
            />
          </Box>
          <Box gridColumn={{ _: "span 6", md: "span 3" }} mt={{ _: "16px", md: "0" }}>
            <ImpactCardComponent
              Icon={
                <Icon
                  color={theme.colors.purple[700]}
                  type={"Scaled - N - Star"}
                  varient={"scaled"}
                  size={48}
                />
              }
              stats="4.8/5"
              title="Learner Rating"
            />
          </Box>
        </GridContainer>
      </Container>
      <Box mt={{ _: "56px", md: "80px", zIndex: 0 }}>
        <SliderText data={data} />
      </Box>
      <Blob
        width={"821px"}
        height={"821px"}
        background={theme.colors.purple[300]}
        left={"-366px"}
        top={"-273px"}
      />
      <Blob
        width={"770px"}
        height={"770px"}
        background={theme.colors.primary[300]}
        left={"1140px"}
        top={"0px"}
      />
      <Blob
        width={"821px"}
        height={"821px"}
        background={theme.colors.purple[300]}
        left={"1305px"}
        top={"640px"}
      />
    </Box>
  );
};

export default OurImpact;
