import { FC } from "react";

import { StyledImpactCard } from "./impactCard.styles";
import { ImpactCardProps } from "./types";

const ImpactCard: FC<ImpactCardProps> = ({ children, className }) => {
  return <StyledImpactCard className={className}>{children}</StyledImpactCard>;
};

export default ImpactCard;
