import { useEffect, useState } from "react";

import { getBlogsData } from "@/client/OneistoxApiClient";

import headDetails from "@/content/head";

import Box from "@/design-system/Box";

import { useAppSelector } from "@/hooks/hooks";

import India from "@/components/Home/INDIA/India";
import Row from "@/components/Home/ROW/Row";
import Spinner from "@/components/LMSV3/common/Spinner";

export const getStaticProps = async () => {
  const content = headDetails;
  const blogs = await getBlogsData();
  return { props: { data: content, blogsList: blogs.data } };
};

const Index = ({ data, blogsList }: any) => {
  const loginDetails = useAppSelector((state) => state?.rootReducer?.auth);

  if (!loginDetails?.userLocation)
    return (
      <Box display={"flex"} justifyContent={"center"} minHeight={"500px"} alignItems={"center"}>
        <Spinner />
      </Box>
    );

  return (
    <>
      {loginDetails?.userLocation === "INDIA" ? (
        <India data={data} blogsList={blogsList} />
      ) : (
        <Row data={data} blogsList={blogsList} />
      )}
    </>
  );
};

export default Index;
