import ExportedImage from "next-image-export-optimizer";
import styled from "styled-components";

import Box from "@/design-system/Box";
import Container from "@/design-system/Container";
import FlexContainer from "@/design-system/FlexContainer";
import GridColumn from "@/design-system/GridColumn";
import GridContainer from "@/design-system/GridContainer";
import Icon from "@/design-system/Icon";
import SectionHeading from "@/design-system/SectionHeading";
import InnovativeSlider from "@/design-system/Slider/InnovativeSlider/InnovativeSlider";

import { theme } from "@/theme";

import InnovativeCard from "./InnovativeCard";

const CardContainer = styled(FlexContainer)`
  gap: 56px;
  margin-top: 48px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 100%;
    margin-left: 24px;
    gap: 24px;
    margin-top: unset;
  }
`;

const ContentContainer = styled(GridContainer)`
  min-height: 845px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-height: 520px;
  }
`;

const ImageSectionContainer = styled(Box)`
  width: 100%;
  margin-top: 48px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: unset;
    /* height: 690px; */
    position: absolute;
    top: -150px;
    z-index: 3;
  }
`;

const UnionTopStyle = styled(Box)`
  position: absolute;
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
    width: 490px;
    height: 490px;
    left: -330px;
    top: -330px;
    z-index: 1;
  }
`;

const UnionBottomStyle = styled(Box)`
  position: absolute;
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
    width: 490px;
    height: 490px;
    right: -111px;
    bottom: -252px;
    z-index: 1;
  }
`;

const InnovativeLearning = () => {
  return (
    <>
      <Box background={{ md: theme.colors.primary["25"] }} paddingTop={{ _: "32px", md: "60px" }}>
        <Container>
          <GridContainer>
            <GridColumn md={"span 6"} mb={{ md: "10px" }} mt={{ md: "16px" }}>
              <Box
                height={{ md: "968px", lg: "938px" }}
                position="absolute"
                top={{ md: "-940px", lg: "-910px" }}
                left={-28}
                display={{ _: "none", md: "block" }}
              >
                <Box
                  display={"inline-block"}
                  height={"100%"}
                  width={"3px"}
                  backgroundImage={theme.gradients.misc[14]}
                />
              </Box>
              <Box position="absolute" display={{ _: "none", md: "block" }} top={52} left={-48}>
                <Icon
                  type={"Scaled - N - Code"}
                  varient={"scaled"}
                  size={40}
                  color={theme.colors.purple[600]}
                  stokeColor={theme.colors.purple[600]}
                />
              </Box>
              <SectionHeading
                streak
                streakTitle={"Innovative Learning"}
                pmd={"16px 32px 16px 32px"}
                heading={
                  <span>
                    An
                    <span style={{ color: theme.colors.purple[700] }}>
                      {" "}
                      Unforgettable Experience
                    </span>
                  </span>
                }
                description={
                  <span>
                    Traditional teaching methods have no place in the modern world. That's why we
                    infuse digital tools with new-age pedagogies to enhance your learning.
                  </span>
                }
              />
              <Box
                height={"250px"}
                position="absolute"
                top={118}
                left={-28}
                display={{ _: "none", md: "block" }}
                zIndex={3}
              >
                <Box
                  display={"inline-block"}
                  height={"100%"}
                  width={"3px"}
                  backgroundImage={theme.gradients.misc[14]}
                />
              </Box>
            </GridColumn>
          </GridContainer>
        </Container>
      </Box>

      <Box
        background={{
          _: "white",
          md: theme.gradients.primaryGradients45deg["7"],
        }}
        width={"100%"}
        minHeight={{ _: "845px", md: "520px" }}
      >
        <Container>
          <ContentContainer>
            <GridColumn md="span 7" xs="span 12">
              <CardContainer flexWrap="wrap">
                <InnovativeCard
                  imgUrl="Scaled - N - Teacher"
                  title={
                    <span>
                      Personalised Feedback and{" "}
                      <span style={{ whiteSpace: "nowrap" }}>Skill-Mapping</span>
                    </span>
                  }
                  details="1:1 feedback sessions help you improve as you learn. A
                      special Skill Map identifies your core competencies so you
                      can leverage them for your career."
                />
                <InnovativeCard
                  imgUrl="Scaled - N - Video"
                  title="Interactive Live Sessions with Experts"
                  details="All lessons are delivered live, so you can interact with
                      mentors as well as your peers from around the world. At
                      Novatr, online learning never feels dull!"
                />
              </CardContainer>
            </GridColumn>
            <GridColumn md="span 5" xs="span 12">
              <ImageSectionContainer overflow={"hidden"}>
                <InnovativeSlider />
              </ImageSectionContainer>
            </GridColumn>
          </ContentContainer>
        </Container>
        <Box
          position={{ md: "absolute" }}
          left="0"
          top={"0"}
          overflow="hidden"
          width={"100%"}
          height="100%"
        >
          <UnionTopStyle>
            <ExportedImage
              src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/union5.svg`}
              fill
              alt=""
            />
          </UnionTopStyle>
          <UnionBottomStyle>
            <ExportedImage
              src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/union5.svg`}
              fill
              alt=""
            />
          </UnionBottomStyle>
        </Box>
      </Box>
    </>
  );
};

export default InnovativeLearning;
