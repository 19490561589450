import ExportedImage from "next-image-export-optimizer";

import Badge from "@/design-system/Badge/badge";
import Box from "@/design-system/Box";
import Typography from "@/design-system/Typography";

import { theme } from "@/theme";

const TestimonialCard = ({ data }: any) => {
  return (
    <Box gridColumn={{ _: "span 12", md: "span 4" }} display={"flex"}>
      <Box
        boxShadow={theme.shadows.primary.lg}
        borderRadius={"8px"}
        display={"flex"}
        flexDirection={{ _: "column-reverse", md: "column" }}
        border={`1px solid ${theme.colors.gray[200]}`}
        overflow={"hidden"}
        mb={{ _: "16px" }}
        zIndex={4}
      >
        <Box
          padding={{ _: "16px", md: "24px 40px 24px 40px" }}
          display={"flex"}
          background={theme.colors.gray[50]}
        >
          <Box
            borderRadius={"100%"}
            overflow={"hidden"}
            width={"56px"}
            height={"56px"}
            mr={{ _: "8px", md: "16px" }}
            mt={"auto"}
            mb={"auto"}
          >
            <ExportedImage
              alt={data.userImage.alt}
              fill
              src={data.userImage.src}
              style={{ objectFit: "cover", objectPosition: "top" }}
            />
          </Box>

          <Box
            display={"flex"}
            flexDirection={{ md: "column" }}
            justifyContent={{ _: "space-between", md: "unset" }}
            flex={{ _: "1", md: "unset" }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              height={"100%"}
            >
              <Box mb={{ _: "0px" }}>
                <Typography
                  varient="bodys"
                  weightVarient="semibold"
                  mdVarient="heading6"
                  mdWeightVarient="semibold"
                >
                  {data.name}
                </Typography>
              </Box>
              <Box mb={"8px"}>
                <Typography varient="captionl" weightVarient="regular">
                  {data.designation}
                </Typography>
              </Box>
            </Box>
            {data?.badgeText && (
              <>
                <Box display={{ _: "none", md: "inherit" }}>
                  <Badge
                    colorVarient={data.badgeColor}
                    icon={data.badgeIcon}
                    // color={data.badgeColor}
                    leadingIcon={true}
                    size={"md"}
                    text={data.badgeText}
                  />
                </Box>
                <Box display={{ _: "flex", md: "none" }} alignItems="center">
                  <Badge
                    colorVarient={data.badgeColor}
                    icon={data.badgeIcon}
                    leadingIcon={true}
                    size={"sm"}
                    text={data.badgeText}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Box
          background={theme.colors.base.white}
          height="100%"
          display="flex"
          flexDirection={"column"}
          justifyContent="space-between"
        >
          <Box p={{ _: "16px 24px 16px 24px", md: "24px 48px 24px 48px" }}>
            <Typography varient="bodys" weightVarient="regular">
              {data.description}
            </Typography>
          </Box>
          {data.cohort && (
            <Box
              p={{ _: "16px 24px 16px 24px", md: "16px 48px 24px 48px" }}
              display={{ _: "none", md: "block" }}
            >
              {data.cohort}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TestimonialCard;
