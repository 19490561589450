import { FC } from "react";

import { StyledPlacementCard } from "./placementCard.styles";
import { PlacementCardProps } from "./types";

const PlacementCard: FC<PlacementCardProps> = ({ children, className, type }) => {
  return (
    <StyledPlacementCard className={className} type={type}>
      {children}
    </StyledPlacementCard>
  );
};

export default PlacementCard;
