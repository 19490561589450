import styled from "styled-components";

import { useGetNextCohortDateQuery } from "@/client/lmsApiClient";

import Box from "@/design-system/Box";
import Container from "@/design-system/Container";
import GridContainer from "@/design-system/GridContainer";
import Typography from "@/design-system/Typography";

import FlexContainer from "@/design-system-v2/FlexContainer";

import { formatNextCohortDate } from "@/utils/utils";

import CoursesDynamicData from "@/content/courses/dynamic-data";

import { theme } from "@/theme";

import CourseCardComponent from "./CourseCardComponent";

const SectionHeadingStyle = styled(Box)`
  display: flex;
  align-items: center;
  grid-column: span 12;
  gap: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    grid-column: span 12;
  }
`;

const TitleStyle = styled(Box)`
  display: flex;
  align-items: center;
  grid-column: 1 / span 12;
  margin-top: 8px;
  margin-bottom: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    grid-column: span 12;
    margin-top: 16px;
    margin-bottom: unset;
  }
`;

const SubTitleStyle = styled(Box)`
  display: none;
  text-align: start;
  justify-content: flex-start;
  grid-column: span 12;
  margin-top: 8px;
  margin-bottom: 32px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 56px;
  }
`;

const BoxStyle = styled(Box)`
  background-color: ${theme.colors.primary["25"]};
  padding-top: 48px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: 40px;
    padding-bottom: 96px;
  }
`;

const OurCourses = ({ ctaExplore }: { ctaExplore: any }) => {
  const dynamicData = CoursesDynamicData;
  const { data: nextCohortDate } = useGetNextCohortDateQuery();
  const { BIM_A, BIM_C, MCD, INT_DES } = nextCohortDate || {};
  return (
    <BoxStyle id="courses" style={{ marginTop: ctaExplore ? "20px" : "0px" }}>
      <Container>
        <GridContainer padding={{ _: "16px 8px", md: "16px 64px" }}>
          <SectionHeadingStyle>
            <Box
              style={{ backgroundColor: theme.colors.primary[50] }}
              display={"flex"}
              padding={"8px 12px"}
              borderRadius={"20px"}
            >
              <Typography
                varient="bodyl"
                weightVarient="regular"
                mdVarient="bodys"
                mdWeightVarient="medium"
                color={theme.colors.primary[700]}
              >
                Our Courses
              </Typography>
            </Box>
          </SectionHeadingStyle>
          <TitleStyle>
            <Typography
              varient="heading1"
              weightVarient="semibold"
              color={theme.colors.primary[800]}
              as={"h2"}
            >
              Your Future Starts Here
            </Typography>
          </TitleStyle>
          <SubTitleStyle>
            <Typography varient="bodyl" weightVarient="regular" color={theme.colors.gray[600]}>
              Get closer to your professional goal with online certification courses in
              architecture, <br />
              engineering, and design.
            </Typography>
          </SubTitleStyle>
        </GridContainer>
        <FlexContainer
          style={{ flexWrap: "wrap" }}
          justifyContent={"center"}
          paddingBottom={{ _: "48px", md: "80px 0px 80px 0px" }}
        >
          <Box width={{ _: "100%", md: "45%" }} m={theme.spacing(5)}>
            <CourseCardComponent
              imgSrc={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/bim.png`}
              imgAlt="BIM Professional Course for Architects"
              courseType="BIM"
              courseSubText="Enter the AEC industry's most in-demand specialisation."
              adText="Enter the AEC industry's most in-demand specialisation."
              programDuration={
                dynamicData.find((c) => c.courseId === "BIM")?.upcomingCohortDuration
              }
              nextCohort={formatNextCohortDate(BIM_A?.date)}
              idx={0}
            />
          </Box>
          <Box width={{ _: "100%", md: "45%" }} m={theme.spacing(5)}>
            <CourseCardComponent
              imgSrc={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/mcd.png`}
              imgAlt="Master Computational Design Course"
              courseType="MCD"
              courseSubText="Join the league of the top 1% architects and engineers."
              adText="Join the league of the top 1% architects and engineers."
              programDuration="10 Months, online (10-12 hours/week)"
              nextCohort={formatNextCohortDate(MCD?.date)}
              idx={1}
            />
          </Box>
          <Box width={{ _: "100%", md: "45%" }} m={theme.spacing(5)}>
            <CourseCardComponent
              imgSrc={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/civil.jpg`}
              imgAlt="BIM Professional Course for Civil Engineers"
              courseType="BIM_C"
              courseSubText="The future is bright for those who are a part of the digital transformation."
              adText="The future is bright for those who are a part of the digital transformation."
              programDuration="8 Months, online (10-12 hours/week)"
              nextCohort={formatNextCohortDate(BIM_C?.date)}
              idx={1}
            />
          </Box>
          <Box width={{ _: "100%", md: "45%" }} m={theme.spacing(5)}>
            <CourseCardComponent
              imgSrc={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/interior.png`}
              imgAlt="Post Graduate Program in Interior Design & Future Tech"
              courseType="INT_DES"
              courseSubText="Shape Your Interior Design Career in future ready skills."
              adText="Shape Your Interior Design Career in future ready skills."
              programDuration="6 Months, online"
              nextCohort={formatNextCohortDate(INT_DES?.date)}
              idx={1}
            />
          </Box>
        </FlexContainer>
      </Container>
    </BoxStyle>
  );
};
export default OurCourses;
