import { FC } from "react";

import { StyledCourseCard } from "./coursecard.styles";
import { CourseCardProps } from "./types";

const CourseCard: FC<CourseCardProps> = ({ children, className, idx }) => {
  return (
    <StyledCourseCard idx={idx} className={className}>
      {children}
    </StyledCourseCard>
  );
};

export default CourseCard;
